<template>
  <!-- 代码详情 -->
  <div class="cson-bg">
    <div class="codeDetail">
      <div
        class="container"
        v-loading="loading"
        element-loading-text="正在加载中"
        element-loading-spinner="el-icon-loading"
      >
        <div class="codeContainer">
          <div class="codeQuestion">
            Leetcode {{ codeInfo.questionNum }}.{{
              language == "en" ? codeInfo.name : codeInfo.chineseName
            }}
          </div>
          <div class="countNums">
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-liulan"
                style="margin-right: 4px"
              ></span
              >{{ codeInfo.pageViews }}</span
            >
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-dianzan"
                style="margin-right: 4px; cursor: pointer"
                @click="likeComments(codeInfo.id)"
              ></span
              >{{ codeInfo.likeNums }}</span
            >
            <span style="margin-right: 12px"
              ><span
                class="iconfont icon-huifu"
                style="margin-right: 6px"
              ></span
              >{{ codeInfo.commentNums }}</span
            >
            <span class="resolutionCounts"
              >{{ codeInfo.answers && codeInfo.answers.length }}种解法</span
            >
            <span class="switchLanguage" @click="switchLanguage">
              <svg
                viewBox="0 0 24 24"
                width="1em"
                height="1em"
                class="css-1lc17o4-icon"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0014.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
                ></path>
              </svg>

              切换到{{ language == "en" ? "中文" : "英文" }}</span
            >
            <el-button
              style="float: right"
              type="primary"
              size="small"
              round
              @click="watchVideo"
              >视频讲解</el-button
            >
          </div>
          <div
            :class="['richTextArea', language]"
            v-html="
              language == 'en'
                ? codeInfo.leetcodeProblem
                : codeInfo.chineseLeetcodeProblem
            "
          ></div>

          <div
            class="answers"
            v-for="(answer, index) in codeInfo.answers"
            :key="index"
          >
            <div class="title">解法{{ index + 1 }}:</div>

            <div class="idea" v-if="answer.idea" v-html="answer.idea"></div>
            <div class="idea" v-else>
              <el-button type="primary" round @click="preferIdea(answer.id)"
                >我来提供解法说明</el-button
              >
              <!--              <div style="margin-top: 12px">-->
              <!--                提供解法说明，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）-->
              <!--              </div>-->
            </div>
            <el-tabs
              v-model="answer.activeTab"
              type="card"
              v-if="
                answer.javaCode || answer.pythonCode || answer.cplusPlusCode
              "
            >
              <el-tab-pane label="Java" name="0" v-if="answer.javaCode">
                <div class="javaCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.javaCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Python" name="1" v-if="answer.pythonCode">
                <div class="pythonCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.pythonCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
              <el-tab-pane label="C++" name="2" v-if="answer.cplusPlusCode">
                <div class="cplusPlusCode" v-highlight>
                  <pre>
                    <code style="margin-top:-15px;" v-text="answer.cplusPlusCode"></code>
                  </pre>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div class="preferResolution">
            <el-button type="success" round @click="preferResolution"
              >提供新的解法</el-button
            >
            <div style="margin-top: 12px">
              提供新的解法，即可免费获得LeetCode视频讲解一天观看券（观看题数范围根据提供说明数决定）
            </div>
          </div>
        </div>
        <div class="commentArea yours" style="position: relative">
          <div
            style="
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              text-align: center;
              line-height: 230px;
              z-index: 999;
              background: rgba(0, 0, 0, 0.2);
              color: #fff;
            "
            v-if="!userInfo || !userInfo.id"
          >
            请先
            <router-link to="/login">
              <el-button type="primary" size="mini" style="margin: 8px"
                >登录</el-button
              ></router-link
            >后发表评论
          </div>
          <el-row>
            <el-col :span="2">
              <img
                class="userInfo avatar"
                v-if="userInfo && userInfo.id"
                :src="userInfo.avatar"
                alt=""
              />
              <img
                class="userInfo avatar"
                v-else
                src="@/assets/company/userLogo.png"
                alt=""
              />
            </el-col>
            <el-col :span="22">
              <el-row>
                <div id="myWangEditorComment">
                  <Toolbar
                    style="
                      border: 1px solid #dcdfe6;
                      border-bottom: none;
                      border-top-left-radius: 5px;
                      border-top-right-radius: 5px;
                    "
                    :editor="editorId"
                    :defaultConfig="toolbarConfig"
                    :mode="mode"
                  />

                  <!-- 编辑器 -->
                  <Editor
                    style="
                      height: 150px;
                      border: 1px solid #dcdfe6;
                      border-top: none;
                      border-bottom-left-radius: 5px;
                      border-bottom-right-radius: 5px;
                    "
                    :editor="editorId"
                    :defaultConfig="editorConfig"
                    :defaultContent="getDefaultContent"
                    :mode="mode"
                    @onChange="onChange"
                    @onCreated="onCreated"
                  />
                </div>
              </el-row>
              <el-row style="margin-top: 12px">
                <el-col :offset="20" :span="4" style="text-align: right">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="publishCodeDetailComment"
                    :disabled="!userInfo || !userInfo.id"
                    >发表评论</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>

        <comments
          v-if="codeInfo.commentNums || codeInfo.commentNums == 0"
          :type="11"
          :comment-total="codeInfo.commentNums"
        ></comments>
      </div>
      <div class="sideadvertisement">
        <SideAdvertisement></SideAdvertisement>
      </div>
    </div>
  </div>
</template>
<script>
import { publishComment } from "@/service/comments";
import comments from "@/components/comments/index";
import SideAdvertisement from "@/components/sideAdvertisement";
import { getCodeDetail } from "@/service/leetcode";
import { mapState } from "vuex";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
export default {
  name: "codeDetail",
  components: { comments, SideAdvertisement, Editor, Toolbar },
  data() {
    return {
      codeInfo: {},
      language: "en",
      editorId: null,

      toolbarConfig: {
        toolbarKeys: [
          "codeBlock",
          "|",
          "bold",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          "emotion",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          "|",
          "redo",
          "undo",
          "|",
          "fullScreen",
        ],
      },
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
      loading: true,
    };
  },
  mounted() {
    this.getCodeDetail();
    this.language = this.$route.query.language;
  },
  methods: {
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
    getCodeDetail() {
      this.loading = true;
      getCodeDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.codeInfo = res.result;
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      let data;
      data = {
        type: false,
        parentId: 0,
        superId: commentId,
        superType: 11,
      };

      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getCodeDetail();
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
    onChange(editor) {
      this.curContent = editor.children;
      this.comments = editor.getHtml();
    },
    publishCodeDetailComment() {
      if (!this.comments || this.comments == "") {
        return this.$message.error("评论不能为空！");
      }
      const data = {
        content: this.comments,
        type: true,
        superId: this.$route.query.id,
        parentId: 0,
        superType: 11,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success("发表评论成功！");
          this.getCodeDetail();
          this.$bus.$emit("updateCommentList", "update");
          this.comments = "";
          this.editorId.clear();
        }
      });
    },
    preferResolution() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.$router.push(
        `/leetcodeClassification/preferResolution?id=${this.codeInfo.id}`
      );
    },
    preferIdea(answerId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.$router.push(
        `/leetcodeClassification/preferIdea?problemId=${this.codeInfo.id}&answerId=${answerId}`
      );
    },
    switchLanguage() {
      this.$route.query.language = this.language == "en" ? "ch" : "en";
      this.language = this.language == "en" ? "ch" : "en";
    },
    watchVideo() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (!this.userInfo.phone || this.userInfo.phone == "") {
        this.$router.push("/center/account");
        return this.$message({
          message: "请先绑定手机！",
          type: "info",
          duration: 5000,
        });
      }
      if (this.codeInfo && this.codeInfo.resourceId) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message:
            "讲解视频只有部分讲解开放<br>完整视频请购买《LeetCode千题视频讲解》课程",
          type: "warning",
        });
        this.$router.push(`/watchVideo?id=${this.codeInfo.id}`);
      } else {
        this.$confirm(
          "讲解视频只有部分讲解开放<br>完整视频请购买《LeetCode千题视频讲解》课程",
          "",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(() => {
            this.$router.push("/course/2");
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.query.language) {
        this.language = this.$route.query.language;
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("../../assets/leetcode/richtext.css");
.cson-bg {
  background-color: #f4f4f4;
}
.codeDetail {
  padding: 20px 0;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  .container {
    width: 880px;
    .codeContainer {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 12px;
      padding: 30px;
      .codeQuestion {
        font-size: 18px;
        font-family: Poppins-Medium, Poppins;
        font-weight: 500;
        color: #34495e;
        line-height: 20px;
      }
      .countNums {
        margin-top: 20px;
        line-height: 32px;
      }
      .resolutionCounts {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #0a7aff;
        line-height: 18px;
        padding: 4px 8px;
        background: rgba(10, 122, 255, 0.1);
      }
    }
    .title {
      font-size: 16px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #0075f6;
      line-height: 20px;
    }
  }
  .sideadvertisement {
    width: 300px;
  }
}
.commentArea {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.richTextArea {
  margin-top: 30px;
}
.answers {
  padding: 20px 0;
  .title {
    font-size: 16px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #0075f6;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .idea {
    padding: 16px;
    background: rgba(0, 117, 246, 0.1);
    border-radius: 12px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #0075f6;
    line-height: 20px;
    margin-bottom: 20px;
  }
}
.preferResolution {
  background: rgba(45, 166, 65, 0.1);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #2da641;
  line-height: 12px;
  padding: 16px;
}
.switchLanguage {
  margin-left: 12px;
  cursor: pointer;
  line-height: 24px;
}
</style>
